/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    a: "a",
    div: "div",
    p: "p",
    h3: "h3",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components), {HrefLangManager, SideBySide, LinkWithQuery, LandingPageCta} = _components;
  if (!HrefLangManager) _missingMdxReference("HrefLangManager", true);
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  if (!LinkWithQuery) _missingMdxReference("LinkWithQuery", true);
  if (!SideBySide) _missingMdxReference("SideBySide", true);
  return React.createElement(React.Fragment, null, React.createElement(HrefLangManager, {
    alternates: [['x-default', 'https://www.hear.com/about-us/how-it-works/'], ['en', 'https://www.hear.com/about-us/how-it-works/'], ['en-US', 'https://www.hear.com/about-us/how-it-works/'], ['en-CA', 'https://ca.hear.com/about-us/how-it-works/']]
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "3-steps-to-hearing-success",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#3-steps-to-hearing-success",
    "aria-label": "3 steps to hearing success permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "3 steps to hearing success"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "We are your personal guides, leading you every step of the way to the hearing aid that is just right for you. Let our experts point you to the perfect hearing aid – one that fits your lifestyle and budget. At hear.com, better hearing only takes 3 easy steps:"), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/how-it-works-step-1.jpg"
  }, React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "1-speak-to-an-expert",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#1-speak-to-an-expert",
    "aria-label": "1 speak to an expert permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "1. Speak to an expert"), React.createElement(_components.p, {
    className: "c-md-p"
  }, "Your first step is to speak with one of our hearing aid experts over the phone. Simply fill out the consultation request form, or give us a call directly, and we would be happy to assist you. The consultation is absolutely free, unbiased, and without obligation. We will discuss your current hearing situation and provide you with expert advice on finding the right solution to your hearing loss. We will go over every bit of relevant information with you – hearing aid brands, models, ", React.createElement(LinkWithQuery, {
    to: "/hearing-aids/prices/"
  }, "prices"), ", ", React.createElement(LinkWithQuery, {
    to: "/hearing-aids/financial-assistance/"
  }, "financing"), ", ", React.createElement(LinkWithQuery, {
    to: "/hearing-aids/health-insurance/"
  }, "insurance"), ", etc.")), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/how-it-works-step-2.jpg"
  }, React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "2-take-a-hearing-test",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#2-take-a-hearing-test",
    "aria-label": "2 take a hearing test permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "2. Take a hearing test"), React.createElement(_components.p, {
    className: "c-md-p"
  }, "Our hearing aid expert will look for the closest Partner Provider in your area and schedule your ", React.createElement(_components.a, {
    href: "/hearing-test/",
    className: "c-md-a"
  }, "hearing test"), " appointment. At your appointment, our Partner will evaluate your hearing, recommend a specific hearing aid model, and fit them to your individual needs. After you’ve been fitted with your new hearing aids, you will take home your new devices and begin your 45-day trial with a 100% money-back guarantee. Test your hearing aids everywhere you go – at home with your family, at work with coworkers, or at the park with your grandchildren – and experience the difference in your everyday life.")), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/how-it-works-step-3.jpg"
  }, React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "3-enjoy-better-hearing",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#3-enjoy-better-hearing",
    "aria-label": "3 enjoy better hearing permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "3. Enjoy better hearing"), React.createElement(_components.p, {
    className: "c-md-p"
  }, "During the trial period, you will have the opportunity to evaluate how much your hearing has improved since you started wearing hearing aids. If you love your new hearing aids – and we know you will – you can then just keep them. You will never be stuck with hearing aids you don’t like or don’t work for you – we guarantee it! We will help take care of any financing or insurance questions you may have so that you can continue to enjoy your renewed quality of life.")), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/how-it-works-hearcom-team.jpg"
  }, React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "the-hearcom-advantages",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#the-hearcom-advantages",
    "aria-label": "the hearcom advantages permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "The hear.com advantages"), React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Free consultation with a hearing aid expert"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Unbiased, expert advice on hearing loss and hearing aids"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Risk-free trial of the best hearing aids"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "2,000+ Partner Providers across the United States"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "92% hearing success rate"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Financing and insurance options available"), "\n")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Are you ready for a free consultation with one of our hearing aid experts, all from the comfort of your own home? Just click on the button below and we will contact you shortly."), "\n", React.createElement(LandingPageCta, {
    copy: "Start No-risk Trial",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
